export default {
    name: 'TrainingSpeaking',
    data(){
        return{

        }
    },
    methods: {
        returnBack () {
            this.$router.push({
                path: '/user/trainingSpeaking',
            })
        },
    }
}
