<template>
    <div class="trainingSpeaking pageContent">
        <!-- <div class="bar">
				<span @click="returnBack"
                ><i class="iconfont icon-papa-rt"></i
                >外教1v1口语陪练模考</span
                >
        </div> -->
        <router-view name="training"></router-view>
    </div>
</template>

<script>
import TrainingSpeaking from './TrainingSpeaking'

export default TrainingSpeaking
</script>

<style lang="less" scoped>

.trainingSpeaking {
    margin-top: 30px;
    & > .bar {
        width: 100%;
        height: 30px;
        background-color: #e6e6e6;
        display: flex;
        justify-content: left;
        align-items: center;


        span {
            margin-left: 20px;
            color: #666;
            cursor: pointer;

            i {
                font-size: 12px;
                color: #999;
            }
        }
    }
}
</style>
